import {useEffect, useState} from 'react';

export function useButtonClick(name = 'hasClicked'): [boolean, () => void] {
	const [hasClicked, setHasClicked] = useState<boolean>(false);

	useEffect(() => {
		const savedValue = localStorage.getItem(name);
		setHasClicked(savedValue === 'true');
	}, [name]);

	const handleClick = () => {
		setHasClicked(true);
		localStorage.setItem(name, String(true));
	};

	return [hasClicked, handleClick];
}

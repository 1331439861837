import {host as PROD_HOST, pages as PROD_URLS} from '../../prod-urls.config';

export {PROD_HOST};

export enum ProjectHrefs {
	vkWorkSpace = '/vk-work-space',
	vkWorkMail = '/vk-work-mail',
	vkWorkMessenger = '/messenger',
	vkWorkDisk = '/vk-work-disk',
	office = '/office',
	vkTeams = '/vk-teams',
	vkTeamsDownload = '/download',
	contacts = '/contacts',
	page404 = '/404',
	oldBrowser = '/old-browser',
	onpremise = '/onpremise',
	education = '/education',
	tariffs = '/tariffs',
	smallMediumBusiness = '/smb',
	partners = '/partners',
	research = '/research',
	promo = '/promo',
	retail = '/retail',
	meetings = '/meetings',
	security = '/security',
	docs = '/docs',
	verify = '/verify',
}

export const PAGES = {
	workSpace: {href: PROD_URLS.workSpace, name: 'VK WorkSpace', projectHref: ProjectHrefs.vkWorkSpace},
	workMail: {href: PROD_URLS.workMail, name: 'VK WorkMail', projectHref: ProjectHrefs.vkWorkMail},
	workMessenger: {
		href: PROD_URLS.workMessenger,
		name: 'VK WorkMessenger',
		projectHref: ProjectHrefs.vkWorkMessenger,
	},
	workDisk: {href: PROD_URLS.workDisk, name: 'VK WorkDisk', projectHref: ProjectHrefs.vkWorkDisk},
	office: {href: PROD_URLS.office, name: 'office', projectHref: ProjectHrefs.office},
	teams: {href: PROD_URLS.teams, name: 'VK Teams', projectHref: ProjectHrefs.vkTeams},
	teamsDownload: {
		href: PROD_URLS.teamsDownload,
		name: 'Скачать VK Teams',
		projectHref: ProjectHrefs.vkTeamsDownload,
	},
	verify: {href: PROD_URLS.verify, name: 'Verify'},
	contacts: {href: PROD_URLS.contacts, name: 'Contacts', projectHref: ProjectHrefs.contacts},
	404: {href: PROD_URLS[404], name: '404', projectHref: ProjectHrefs.page404},
	'old-browser': {
		href: PROD_URLS['old-browser'],
		name: 'Old browser',
		projectHref: ProjectHrefs.oldBrowser,
	},
	onpremise: {href: PROD_URLS.onpremise, name: 'On premise', projectHref: ProjectHrefs.onpremise},
	education: {href: PROD_URLS.education, name: 'Education', projectHref: ProjectHrefs.education},
	tariffs: {href: PROD_URLS.tariffs, name: 'Tariffs', projectHref: ProjectHrefs.tariffs},
	smallMediumBusiness: {
		href: PROD_URLS.smallMediumBusiness,
		name: 'Small Medium Business',
		projectHref: ProjectHrefs.smallMediumBusiness,
	},
	partners: {href: PROD_URLS.partners, name: 'Partners', projectHref: ProjectHrefs.partners},
	research: {href: PROD_URLS.research, name: 'Research', projectHref: ProjectHrefs.research},
	promo: {href: PROD_URLS.promo, name: 'Promo', projectHref: ProjectHrefs.promo},
	retail: {href: PROD_URLS.retail, name: 'Retail', projectHref: ProjectHrefs.retail},
	meetings: {href: PROD_URLS.meetings, name: 'Meetings', projectHref: ProjectHrefs.meetings},
	security: {href: PROD_URLS.security, name: 'Security', projectHref: ProjectHrefs.security},
	docs: {href: PROD_URLS.docs, name: 'Docs', projectHref: ProjectHrefs.docs},
};

export type PageName = keyof typeof PROD_URLS;

export const LINKS = {
	telegram: 'https://t.me/vk_workspace_official',
	dzen: 'https://zen.yandex.ru/id/623c60032d06b359f85ab327',
	domainManageAll: 'https://help.mail.ru/biz/domain/manage/all',
	vkCom: 'https://vk.com/vkworkspace',
	bizHelp: 'https://help.mail.ru/biz/',
	teamsHelp: 'https://help.mail.ru/biz/myteam',
	bizOnpremise: '/onpremise/',
	bizBlog: '/blog/',
	bizSupport: 'https://help.mail.ru/biz/support',
	bizPrivacy: 'https://help.mail.ru/biz/legal/privacy',
	bizGooglePrivacy: 'https://policies.google.com/privacy',
	bizTermsofuse: 'https://help.mail.ru/biz/legal/termsofuse',
	bizGoogleTermsOfService: 'https://policies.google.com/terms',
	tariffs: 'https://help.mail.ru/biz/teambox/balance',
	appStore: 'https://apps.apple.com/ru/app/id1459096573',
	move: 'https://help.mail.ru/biz/domain/manage/migration',
	login: 'https://account.mail.ru/login?success_redirect=https%3A%2F%2Fbiz.mail.ru&lang=ru_RU',
	signup: 'https://account.mail.ru/signup?back=https%3A%2F%2Fbiz.mail.ru&lang=ru_RU',
	chrome: 'https://www.google.com/intl/ru_ru/chrome/',
	firefox: 'https://www.mozilla.org/ru/firefox/new/',
	opera: 'https://www.opera.com/ru',
	edge: 'https://www.microsoft.com/ru-ru/edge',
	botApi: 'https://teams.vk.com/botapi/',
	miniApps: 'https://biz.mail.ru/docs/on-premises/Teams/mini-apps/mini-apps/',
	startups: 'https://mcs.mail.ru/startup/',
	promoThreeMonthsFreeTerms: 'https://help.mail.ru/biz/legal/three-months-free-terms',
	downloadWindows: 'https://vkteams-www.hb.bizmrg.com/win/x32/vkteamssetup.exe',
	downloadMacOSAppStore: 'https://apps.apple.com/ru/app/id1473826998',
	downloadMacOSDMG: 'https://vkteams-www.hb.bizmrg.com/mac/x64/vkteams.dmg',
	downloadLinux: 'https://vkteams-www.hb.bizmrg.com/linux/x64/vkteams.tar.xz',
	downloadAndroidGooglePlay: 'https://play.google.com/store/apps/details?id=ru.mail.biz.avocado',
	downloadAndroidRuStore: 'https://apps.rustore.ru/app/ru.mail.biz.avocado',
	downloadIOS: 'https://itunes.apple.com/ru/app/id1459096573?mt=8',
	downloadWeb: 'https://myteam.mail.ru/webim/',
	promoHalfPriceTerms: 'https://help.mail.ru/biz/legal/half-price-terms',
	saasDocs: 'https://biz.mail.ru/docs/saas',
	docs: 'https://biz.mail.ru/docs',
	saasDocsSpecialOffer: 'https://biz.mail.ru/docs/saas/LegalInformation/Hidden/special-offer/',
	saasDocsDiskOGift: 'https://biz.mail.ru/docs/saas/LegalInformation/Hidden/disko-terms/',
	saasDocsTroubleshooting: 'https://biz.mail.ru/docs/saas/FAQ/troubleshooting/index.html#_2',
	saasDocsTariffsPayment: 'https://biz.mail.ru/docs/saas/TariffsPayment/payment-methods/index.html',
	saasDocsWorkMailMigration: 'https://biz.mail.ru/docs/saas/WorkMail/mail-migration/index.html',
	onPremisesDocs: 'https://biz.mail.ru/docs/on-premises',
	cloudVkCom: 'https://cloud.vk.com/',
	docsSaasSupport: 'https://biz.mail.ru/docs/saas/Support/support/index.html',
	helpDomainMigration: 'https://help.mail.ru/biz/domain/migration',
	helpDomainMigrationGmail: 'https://help.mail.ru/biz/domain/migration#gmail',
	helpDomainVerificationSettings: 'https://help.mail.ru/biz/domain/verification_settings/other/confirm',
	bizMail: 'https://biz.mail.ru/',
	bizMailTariffs: 'https://biz.mail.ru/tariffs/',
	communicationAppVideo: 'https://biz-landings-static.hb.ru-msk.vkcs.cloud/media/1Timeline%201.mp4',
	caseSamolet:
		'https://biz.mail.ru/blog/articles/kak-razvernut-korporativnuyu-poctu-dlya-10-000-sotrudnikov-francaizi-bez-pokupki-serverov-keis-samoleta',
	caseSitidraiv:
		'https://biz.mail.ru/blog/articles/v-pyatnicu-nas-zablokiroval-slack-a-v-ponedelnik-my-uze-rabotali-v-vk-teams-keis-sitidraiva',
	caseSimbirsoft:
		'https://biz.mail.ru/blog/articles/kak-za-odin-den-pereexat-s-microsoft-na-novyi-servis-dlya-zvonkov-podklyucit-poctu-i-perenesti-1200-catov-v-messendzere-s-pomoshhyu-robota-keis-simbirsoft',
	caseAll: 'https://biz.mail.ru/blog/?filter=5',
	caseRosgosstrax:
		'https://biz.mail.ru/blog/articles/avtomatizirovali-vydacu-poctovyx-yashhikov-i-pereveli-na-biznes-poctu-30-tysyac-rabotnikov-keis-rosgosstraxa-i-vk-workmail',
	caseIndever:
		'https://biz.mail.ru/blog/articles/kak-federalnaya-set-indever-zamenila-poctu-oblako-i-prilozenie-dlya-zvonkov-na-platformu-vk-workspace',
	caseBigama:
		'https://biz.mail.ru/blog/articles/iskali-zamenu-microsoft-i-sravnivali-servisy-po-57-parametram-vyigral-vk-workspace-keis-bigama',
	caseVK: 'https://biz.mail.ru/blog/articles/kak-vk-teams-pomogaet-11-tysyacam-sotrudnikov-byt-castyu-odnoi-komandy',
	caseRtmis:
		'https://biz.mail.ru/blog/articles/kak-bessovno-perevesti-pocti-1000-sotrudnikov-s-microsoft-365-na-vk-workspace-keis-rt-mis',
	docsSaasTariffsBillsPromocodes: 'https://biz.mail.ru/docs/saas/tariffs-bills/promocodes/index.html',
};

export const MAILS = {
	digitalTechCorpMail: 'mailto:digital.tech@corp.mail.ru',
	docBizMail: 'mailto:doc@biz.mail.ru',
};
export const MICRO_DATA = {
	'@context': 'https://schema.org',
	'@type': 'Organization',
	name: 'VK',
	address: {
		'@type': 'PostalAddress',
		streetAddress: 'Ленинградский пр., д. 39, стр. 79',
		addressLocality: 'г. Москва',
		postalCode: '125167',
	},
	url: PROD_HOST,
};

export const TEMPORARY_LINKS_DATES = {
	[ProjectHrefs.promo]: {
		start: new Date(2024, 8, 2),
		end: new Date(2024, 9, 2),
	},
	newYearHolidays: {
		start: new Date(2023, 11, 30),
		end: new Date(2024, 0, 8),
	},
};

export const PHONE_MASK = '+9 (999) 999-99-99';

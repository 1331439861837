import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';

import {PromoDialog} from '@/components/common/dialogs/promo-dialog/PromoDialog';
import {ProjectHrefs, TEMPORARY_LINKS_DATES} from '@/constants';
import {useButtonClick} from '@/hooks/use-button-click';
import {ILightboxService} from '@/services/lightbox/ILightboxService';

import {useInjection} from './use-injection';
import {useIsDateTimeInRange} from './use-is-date-time-in-range';
import {useMediaQueryMatch} from './use-media-query-match';
import {usePythonSettings} from './use-python-settings';

const CURSOR_AND_DOCUMENT_CLOSE_BUTTON_MIN_DISTANCE_PX = 10;

export function usePromoOnCloseLightbox() {
	const {isAuth, hasDomain} = usePythonSettings();
	const {isMatchDate} = useIsDateTimeInRange(
		TEMPORARY_LINKS_DATES[ProjectHrefs.promo].start,
		TEMPORARY_LINKS_DATES[ProjectHrefs.promo].end,
	);
	const [isDesktop] = useMediaQueryMatch('(min-width: 1024px)');
	const [hasClicked, handleClick] = useButtonClick('hasClickedPromo');
	const router = useRouter();
	const isProhibitedPage = router.asPath.includes(ProjectHrefs.verify);
	const shouldActivateBanner =
		(!isAuth || (isAuth && !hasDomain)) && isMatchDate && isDesktop && !hasClicked && !isProhibitedPage;
	const [LightboxService] = useInjection(ILightboxService);
	const [wasShown, setWasShown] = useState(false);

	useEffect(() => {
		if (!shouldActivateBanner) {
			return;
		}
		if (wasShown) {
			return;
		}

		const listener = (event: MouseEvent) => {
			if (event.clientY < CURSOR_AND_DOCUMENT_CLOSE_BUTTON_MIN_DISTANCE_PX) {
				LightboxService.openReadonly(PromoDialog, {
					onClick: handleClick,
				});
				setWasShown(true);
			}
		};

		document.addEventListener('mouseleave', listener);
		return () => document.removeEventListener?.('mouseleave', listener);
	}, [LightboxService, handleClick, shouldActivateBanner, wasShown]);
}
